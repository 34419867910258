import React from 'react';
import { useTranslation } from 'react-i18next';

export const PhoneErrorMessage = () => {
  const { t } = useTranslation()
  return t("forms.formValidationPhoneMessage");
};

export const EmailErrorMessage = () => {
  const { t } = useTranslation()
  return t("forms.formValidationEmailMessage");
};

export const EmailErrorRequiredMessage = () => {
  const { t } = useTranslation()
  return t("forms.formValidationEmailRequiredMessage");
};

export const NameErrorRequiredMessage = () => {
  const { t } = useTranslation()
  return t("forms.formValidationNameRequiredMessage");
};

export const AddressErrorMessage = () => {
  const { t } = useTranslation()
  return t("forms.formValidationAddressMessage");
};

export const TermsErrorRequiredMessage = () => {
  const { t } = useTranslation()
  return t("forms.formValidationTermsRequiredMessage");
};

export const Terms2ErrorRequiredMessage = () => {
  const { t } = useTranslation()
  return t("forms.formValidationTerms2RequiredMessage");
};