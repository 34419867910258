import cx from 'classnames';
import { FastField, Form, useFormikContext, withFormik } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';

import { getCookie, getReferrer, getUTM } from '../../helpers';
import { useLangContext } from '../../utils/lang';
import { Button2 } from '../button2';
import styles from './form2.module.css';
import { EmailErrorMessage, EmailErrorRequiredMessage, PhoneErrorMessage } from './forms-error-messages';
import jobFormStyles from './job-reply-form.module.css';

const FILE_SIZE = 160 * 1024
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, "phone", function() {
  return this.test("phone", "Telefonní číslo musí obsahovat 9 znaků", value =>
    rePhoneNumber.test(value)
  )
});


export const JobReplyFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  btnType = "secondary",
  btnText = "Jetzt bestellen",
  form_name = "contact-form_new",
}) => {
  const [checkTerms2, setCheckTerms2] = useState(false)
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  return (
    <Form name={form_name} method="post" className={cx(jobFormStyles.jobForm)}>
      <div className={cx(styles.inputField, jobFormStyles.telInput)}>
        <FastField
          component="input"
          type="text"
          name="phone"
          className={cx(styles.input)}
        />
        {touched.phone && errors.phone && (
          <span className={cx(styles.error)}>{errors.phone}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.emailInput)}>
        <FastField
          component="input"
          type="text"
          name="email"
          className={styles.input}
          placeholder={t("forms.onlineOrderFormEmailLabel")}
        />
        {touched.email && errors.email && (
          <span className={styles.error}>{errors.email}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.firstnameInput)}>
        <FastField
          component="input"
          type="text"
          name="firstname"
          className={styles.input}
          placeholder={t("forms.onlineOrderFormNamesLabel")}
        />
        {touched.firstname && errors.firstname && (
          <span className={styles.error}>{errors.firstname}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.surnameInput)}>
        <FastField
          component="input"
          type="text"
          name="surname"
          className={styles.input}
          placeholder={t("forms.onlineOrderFormNamesLabel")}
        />
        {touched.surname && errors.surname && (
          <span className={styles.error}>{errors.surname}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.commentInput)}>
        <FastField
          component="textarea"
          type="text"
          name="comment"
          className={styles.input}
          placeholder={"Message"}
        />
        {touched.comment && errors.comment && (
          <span className={styles.error}>{errors.comment}</span>
        )}
      </div>
      {/* <div className={cx(styles.inputField, jobFormStyles.fileInput)}> */}
        {/* <FastField
          component="input"
          type="file"
          name="file"
          component="input"
          title="Select a file"
          placeholder="Choose a file"
          className={styles.input}
          value={values.file}
        /> */}
        {/* <input
          title="Select a file"
          placeholder="Choose a file"
          className={styles.input}
          id="file"
          name="file"
          type="file"
          onChange={(event) => {
            // let reader = new FileReader();
            let file = event.currentTarget.files[0];
            // reader.readAsDataURL(file);
            // reader.readAsArrayBuffer(file);
            // console.log("---", new File(event.currentTarget.files, file.name));
            setFieldValue("file", file);
            // reader.onload = function() {
            //   console.log(reader.result);
            //   setFieldValue("file", reader.result);
            // };
          }}
        />
        {touched.file && errors.file && (
          <span className={styles.error}>{errors.file}</span>
        )} */}
      {/* </div> */}
      <div className={cx(styles.inputField, jobFormStyles.termsInput)}>
        <input
          id="checkTerms2"
          type="checkbox"
          name="checkTerms2"
          checked={checkTerms2}
          onChange={e => {
            setCheckTerms2(e.target.checked)
          }}
        />
        <label htmlFor="checkTerms2">
          {t("forms.mainFormCheckTerms3Label")}
        </label>
      </div>
      <div className={cx(jobFormStyles.submitButton)}>
        <Button2
          color="secondary"
          buttonType="submit"
          disabled={isSubmitting || !checkTerms2}
        >
          {btnText || t("forms.contactForm2.CTA")}
        </Button2>
      </div>
    </Form>
  )
}

export const JobReplyFormLayoutForm = withFormik({
  mapPropsToValues: props => ({
    phone: "+49",
    firstname: "",
    surname: "",
    email: "",
    comment: "",
    // file: "",
    referrer: "",
    ga: "",
    success: false,
    form_name: props.form_name,
  }),
  validationSchema: () =>
    Yup.object().shape({
      phone: Yup.string()
        .min(9, <PhoneErrorMessage />)
        .phone(),
    }),
  email: Yup.string()
    .email(<EmailErrorMessage />)
    .required(<EmailErrorRequiredMessage />),
  firstname: Yup.string()
    .min(2)
    .required(),
  surname: Yup.string()
    .min(2)
    .required(),
  comment: Yup.string()
    .min(2)
    .required(),
  // file: Yup.mixed()
  //   .required("A file is required"),
    // .test(
    //   "fileSize",
    //   "File too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
  handleSubmit: async (
    { phone, firstname, surname, email, comment, form_name },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      const isEn = document.location.pathname.includes("/en")

      const getFormName = () => {
        let formName
        if (form_name) {
          formName = isEn ? `${form_name}_en` : form_name
        } else {
          formName = isEn ? `JobApplyForm_en` : `JobApplyForm`
        }
        return formName
      }

      const data = {
        form_name: getFormName(),
        phone: phone,
        name: firstname,
        surname: surname,
        email: email,
        comment: comment,
        // file: file,
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        roistat: getCookie("roistat_visit"),
        ga: getCookie("_ga"),
      }

      // const formData = new FormData();

      // formData.append("file", file)
      // formData.append("data", JSON.stringify(data));

      await fetch("/api/feedback", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: JSON.stringify(data),
      })
      // await console.log("data", data)
      // await console.log("formData", formData)
      await setSubmitting(false)
      await setFieldValue("success", true)
      setTimeout(() => {
        resetForm()
        window.location.href = isEn ? "/en/thank-you" : "/thank-you"
        window.dataLayer.push({
          event: "ga.pageview",
          pageURL: isEn ? "/en/thank-you-job-apply" : "/thank-you-job-apply",
          pageType: "Purchase",
        })
      }, 300)
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
      alert("Something went wrong, please try again!")
    }
  },
})(JobReplyFormLayout)
