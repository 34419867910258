import React from 'react';

import { JobApplyForm } from '../../components/job-apply-form-modal';
import SEO from '../../components/seo';
import styles from './job.module.css';

// import jobBgSrc from "../../images/job-bg.png"

const JobPage = () => {
  return (
    <>
      <SEO title="Sales" />

      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Sales</h1>
          <p className={styles.text}>
            NutritionPro is one of the fastest-growing Foodtech startups. (500%+ year-on-year growth). 
          </p>
          <p className={styles.text}>
            We produce hundreds of thousands of meals montly and we are the largest company that provides personalized menus with a complete service.
          </p>

          <h3 className={styles.textSubHeader}>
            What will your role in NutritionPro be?
          </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              helping clients on the way to a better body, great condition, and stronger health
            </li>
            <li className={styles.newList}>
              taking over <b>phone, chat and email communication</b> with new and active clients (we don't do any cold calls)
            </li>
            <li className={styles.newList}>
              managing your client base in our automated CRM and ERP system
            </li>
            <li className={styles.newList}>
              <b>develop your sales skills</b> under the guidance of our sales director and at company-paid courses
            </li>
          </ul>
          <h3 className={styles.textSubHeader}>What do we expect from you?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              excellent communication skills, openness, and the ability to get along with different types of people
            </li>
            <li className={styles.newList}>
              sales experience is an advantage but not a prerequisite
            </li>
            <li className={styles.newList}>
              ideally if you have at least one year experience in sales or customer care
            </li>
            <li className={styles.newList}>
              you enjoy a healthy lifestyle, you like good food
            </li>
            <li className={styles.newList}>
              user knowledge of working in Outlook / Google tools
            </li>
            <li className={styles.newList}>
              <b>German language at C1 level minimum</b>
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>What will you get from us?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              motivational financial evaluation and monthly bonus
            </li>
            <li className={styles.newList}>
              fully paid training courses and consultations with leading experts in the field
            </li>
            <li className={styles.newList}>f
              ree corporate lunches and multisport card
            </li>
            <li className={styles.newList}>
              sick-days
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>
            We look forward to your CV and welcome you to our NutritionPro team!
          </h3>
          <p className={styles.text}>Сontact:</p>
          <a className={styles.text} href="mailto:jobs@nutritionpro.cz">
            info@nutritionpro.de
          </a>
          <br />
          {/* <a className={styles.text} href="tel:+420608068463">
            +420 608 068 463
          </a> */}
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Back
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_Sales" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JobPage
